import React, { useEffect, useState } from 'react'
import { Form, Input, Modal, Popover, Tabs, Switch } from 'antd';
import { useTranslation } from 'react-i18next';
import { useForm } from 'antd/lib/form/Form';
import { ChromePicker } from 'react-color'
import {
  BgColorsOutlined
} from '@ant-design/icons';
import styled from 'styled-components';
import { UploadSingleImage } from '../Upload/UploadSingleImage';
import { device } from '../../style/breakpoints/device';
import { MEDIA_URL } from '../../context/API';
import ReactQuill from 'react-quill';

const { TabPane } = Tabs

const convertToSlug = (Text) => {
  return Text
    .toLowerCase()
    .replace(/ /g, '-')
    .replace(/[^\w-]+/g, '')
    ;
}

const ClientModal = (props) => {
  const { t } = useTranslation();
  const { visible, onOk, onCancel, confirmLoading, title, editMode, record } = props
  const [colors, setColors] = useState({
    mainColor: '',
    secondaryColor: '',
    fontColor: ''
  })
  const [mainColorVisible, setMainColorVisible] = useState(false)
  const [secondaryColorVisible, setSecondaryColorVisible] = useState(false)
  const [fontColorVisible, setFontColorVisible] = useState(false)
  const [greetingEn, setGreetingEn] = useState('');
  const [greetingDe, setGreetingDe] = useState('');
  const [greetingFr, setGreetingFr] = useState('');
  const [greetingIt, setGreetingIt] = useState('');
  const [isItemImageVisible, setItemImageVisibility] = useState(false);
  const [isItemsPriceVisible, setItemsPriceVisibility] = useState(false);
  const [isDashboardVisible, setDashboardVisibility] = useState(false);

  const [file, setFile] = useState()
  const [form] = useForm()
  const handleSubmit = () => {
    form.submit()
  }
  const onFinish = (values) => {
    values['slugName'] = (record && record.slugName) || convertToSlug(values['name'])
    values['logo'] = file
    values['greeting'] = {
      'en': greetingEn,
      'de': greetingDe,
      'fr': greetingFr,
      'it': greetingIt,
    }
    values['isItemThumbnailVisible'] = isItemImageVisible
    values['isItemsPriceVisible'] = isItemsPriceVisible
    values['isDashboardVisible'] = isDashboardVisible
    onOk(values)
  }
  useEffect(() => {
    form.setFieldsValue({
      mainColor: colors.mainColor,
      secondColor: colors.secondaryColor,
      fontColor: colors.fontColor,
    });
  }, [colors])
  useEffect(() => {
    if (record) {
      form.setFieldsValue(record)
      setColors({
        mainColor: record.mainColor,
        secondaryColor: record.secondColor,
        fontColor: record.fontColor
      })
      if (record.greeting) {
        const greetingObj = JSON.parse(record.greeting)
        setGreetingEn(greetingObj.en)
        setGreetingDe(greetingObj.de)
        setGreetingFr(greetingObj.fr)
        setGreetingIt(greetingObj.it)
      }
      setItemImageVisibility(!!record.isItemThumbnailVisible)
      setItemsPriceVisibility(!!record.isItemsPriceVisible)
      setDashboardVisibility(!!record.isDashboardVisible)
    } else {
      setGreetingEn('')
      setGreetingDe('')
      setGreetingFr('')
      setGreetingIt('')
    }
  }, [record])

  return (
    <ModalWrapper
      title={title}
      visible={visible}
      onOk={handleSubmit}
      onCancel={onCancel}
      destroyOnClose
      confirmLoading={confirmLoading}
      okText={t('clients.save')}
      cancelText={t('clients.cancel')}
    >
      <Form
        name="normal_edit_client"
        onFinish={onFinish}
        size="large"
        form={form}
        preserve={false}
        validateTrigger='onSubmit'
        labelCol={{ flex: '130px' }}
        labelAlign="right"
        colon={false}
        initialValues={record}
      >
        <Form.Item
          name="name"
          rules={[{ required: true, message: '' }]}
          label={t('clients.company')}
        >
          <Input
            disabled={editMode}
            type="text"
          />
        </Form.Item>
        <Form.Item
          name="apiKey"
          rules={[{ required: true, message: '' }]}
          label={t('clients.apiKey')}
        >
          <Input
            type="text"
          />
        </Form.Item>

        <Form.Item
          label={t('clients.greeting')}
        >
          <Tabs defaultActiveKey='1' type='card' size='small'>
            <TabPane tab='de' key='1' forceRender>
              <ReactQuill defaultValue={greetingDe} onChange={setGreetingDe} theme="snow" />

            </TabPane>
            <TabPane tab='en' key='2' forceRender>
              <ReactQuill defaultValue={greetingEn} onChange={setGreetingEn} theme="snow" />

            </TabPane>
            <TabPane tab='fr' key='3' forceRender>
              <ReactQuill defaultValue={greetingFr} onChange={setGreetingFr} theme="snow" />

            </TabPane>
            <TabPane tab='it' key='4' forceRender>
              <ReactQuill defaultValue={greetingIt} onChange={setGreetingIt} theme="snow" />
            </TabPane>
          </Tabs>
        </Form.Item>
        <Form.Item
          name="mainColor"
          rules={[{ required: false, message: '' }]}
          label={t('clients.mainColor')}
        >
          <Input
            type="text"
            readOnly
            suffix={
              <Popover
                content={
                  <ChromePicker
                    disableAlpha
                    color={colors.mainColor}
                    onChange={(value) => { setColors({ ...colors, mainColor: value.hex }) }}
                  />
                }
                trigger="click"
                visible={mainColorVisible}
                onVisibleChange={(newVisible) => { setMainColorVisible(newVisible) }}
              >
                <BgColorsOutlined />
              </Popover>}
          />
        </Form.Item>
        <Form.Item
          name="secondColor"
          rules={[{ required: false, message: '' }]}
          label={t('clients.secondaryColor')}
        >
          <Input
            type="text"
            readOnly
            suffix={
              <Popover
                content={
                  <ChromePicker
                    disableAlpha
                    color={colors.secondaryColor}
                    onChange={(value) => { setColors({ ...colors, secondaryColor: value.hex }) }}
                  />
                }
                trigger="click"
                visible={secondaryColorVisible}
                onVisibleChange={(newVisible) => { setSecondaryColorVisible(newVisible) }}
              >
                <BgColorsOutlined />
              </Popover>}
          />
        </Form.Item>
        <Form.Item
          name="fontColor"
          rules={[{ required: false, message: '' }]}
          label={t('clients.fontColor')}
        >
          <Input
            type="text"
            readOnly
            suffix={
              <Popover
                content={
                  <ChromePicker
                    disableAlpha
                    color={colors.fontColor}
                    onChange={(value) => { setColors({ ...colors, fontColor: value.hex }) }}
                  />
                }
                trigger="click"
                visible={fontColorVisible}
                onVisibleChange={(newVisible) => { setFontColorVisible(newVisible) }}
              >
                <BgColorsOutlined />
              </Popover>}
          />
        </Form.Item>
        <Form.Item
          name="logo"
          rules={[{ required: false, message: '' }]}
          label={t('clients.logo')}
        >
          <UploadSingleImage onUpload={(file) => setFile(file)} initialFile={record && MEDIA_URL + record.slugName + '/logo'} />
        </Form.Item>
        <Form.Item
          name="statisticsUrl"
          rules={[{ required: false, message: '' }, { type: 'url', message: t('clients.statisticsUrlError') }]}
          label={t('clients.statistics')}
        >
          <Input
            type="url"
          />
        </Form.Item>
        <ThumbnailWrapper
          name="isItemThumbnailVisible"
          rules={[{ required: false, message: '' }]}
          label={t('clients.itemThumbnail')}
        >
          <Switch checked={isItemImageVisible} onChange={setItemImageVisibility} />
        </ThumbnailWrapper>
        <ThumbnailWrapper
          name="isItemsPriceVisible"
          rules={[{ required: false, message: '' }]}
          label={t('clients.price')}
        >
          <Switch checked={isItemsPriceVisible} onChange={setItemsPriceVisibility} />
        </ThumbnailWrapper>
        <DashboardThumbnailWrapper
          name="isDashboardVisible"
          rules={[{ required: false, message: '' }]}
          label={t('clients.dashboard')}
        >
          <Switch checked={isDashboardVisible} onChange={setDashboardVisibility} />
        </DashboardThumbnailWrapper>
      </Form>
    </ModalWrapper>
  )
}
const ModalWrapper = styled(Modal)`
  .ant-modal-body {
    height: fit-content;
    @media ${device.tablet} {
      height: initial;
    }
  }
`
const ThumbnailWrapper = styled(Form.Item)`
  .ant-form-item-label{
    white-space: initial;
  }
  .ant-form-item-label > label {
    height: 65px;
    align-items: flex-start;
  }
`

const DashboardThumbnailWrapper = styled(Form.Item)`
  .ant-form-item-label{
    white-space: initial;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .ant-form-item-label > label {
    height: unset;
    align-items: flex-start;
  }
`
export default ClientModal;
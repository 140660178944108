import React, { useEffect, useState } from 'react'
import { Modal, Spin } from 'antd';
import styled from 'styled-components';
import { device } from '../../style/breakpoints/device';
import OrderForm from '../Form/OrderForm';
import { useAuth } from '../../context/AuthProvider';
import { getFromAPI } from '../../context/API';

export const OrderModal = (props) => {
  const { visible, afterClose, title, values, previewMode, onSubmit, buttonText } = props
  const auth = useAuth()
  const [order, setOrder] = useState()
  const [ready, setReady] = useState()
  useEffect(() => {
    if (values) {
      const url = 'order/get?intOrderID=' + values.intOrderID
      getFromAPI(url, {}, auth)
        .then(res => res.json())
        .then(data => {
          setOrder(data.response[0])
        })
        .catch(err => {

        })
    }
  }, [values])
  useEffect(() => {
    setOrder()
    setReady(false)
  }, [visible])

  return (
    <ModalWrapper
      title={title}
      visible={visible}
      onCancel={afterClose}
      destroyOnClose
      footer={null}
      width={1024}
      wrapClassName={"large-content"}
    >
      <Spin spinning={!ready} >
        <OrderForm editMode previewMode={previewMode} values={order} onReady={setReady} onSubmit={onSubmit} buttonText={buttonText} />
      </Spin>
    </ModalWrapper>
  )
}
const ModalWrapper = styled(Modal)`
  .ant-modal-body {
    height: fit-content;
    @media ${device.tablet} {
      height: initial;
    }
  }
`

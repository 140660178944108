import React from 'react'
import { Modal, Select } from 'antd';
import styled from 'styled-components';
import { device } from '../../style/breakpoints/device';
import { useTranslation } from 'react-i18next';
import { LANGUAGE_ID_MAP } from '../../utils/Constants';
import i18n from '../../i18n';
const { Option } = Select;

const langID = LANGUAGE_ID_MAP[i18n.language]

const OrderItemTooManyModal = (props) => {
  const { visible, onCancel, onOk, title, okText, cancelText, maxItems } = props
  const { t } = useTranslation()

  const disableOk = maxItems < 1

  return (
    <ModalWrapper
      title={title}
      visible={visible}
      destroyOnClose
      okText={okText}
      cancelText={cancelText}
      onOk={onOk}
      onCancel={onCancel}
      okButtonProps={{ disabled: disableOk }}
      cancelButtonProps={{ style: { display: 'none' } }}
    >
      <p>{t('orders.tooManyWarning', { amount: maxItems < 0 ? 0 : maxItems })}</p>

    </ModalWrapper>
  )
}
const ModalWrapper = styled(Modal)`
  .ant-modal-body {
    height: fit-content;
    p{
      margin-right: 20px;
    }
    @media ${device.tablet} {
      height: initial;
    }
  }
`

export default OrderItemTooManyModal
import React, { useEffect, useState } from 'react'
import { Form, Input, Col, Row, Select, Upload, Modal, Button, DatePicker, Spin, AutoComplete, Typography, Checkbox, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { useForm } from 'antd/lib/form/Form';
import {
  UploadOutlined
} from '@ant-design/icons';
import styled from 'styled-components';
import { device } from '../../style/breakpoints/device';
import { getFromAPI, postToAPI } from '../../context/API';
import { openNotificationWithIcon } from '../../utils/Notification';
import { LANGUAGE_ID_DEFAULT, LANGUAGE_ID_MAP } from '../../utils/Constants';
import i18n from '../../i18n';
import { OrderItemModal } from '../Modal/OrderItemModal';
import { OrderItemTable } from '../Table/OrderItemTable';
import _uniqueId from 'lodash/uniqueId';
import _ from 'lodash';
import { OrderAddressModal } from '../Modal/OrderAddressModal';
import moment from 'moment';
import { useAuth } from '../../context/AuthProvider';
import { sortByLanguage } from '../../utils/DataUtils';
import { CopyOrderModal } from '../Modal/CopyOrderModal';
import PhoneInput from '../Input/PhoneInput';
import OrderItemOutOfStockModal from '../Modal/OrderItemOutOfStockModal';
import { alphaNumericSort } from '../../utils/Sorters';
import Tooltip from '../Tooltip/Tooltip';

const { Option } = Select;

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = () => resolve(reader.result);

    reader.onerror = (error) => reject(error);
  });

const OrderForm = (props) => {
  const { values, editMode, previewMode, onSubmit, onReady, buttonText } = props
  const { t } = useTranslation();
  const [orderTypes, setOrderTypes] = useState([])
  const [fileList, setFileList] = useState([])
  const [itemList, setItemList] = useState([])
  const [defaultQuantities, setDefaultQuantities] = useState({})
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const [loadingData, setLoadingData] = useState(false);
  const [updatingOrder, setUpdatingOrder] = useState(false);
  const [intItemID, setIntItemID] = useState()
  const [intOrderNo, setIntOrderNo] = useState()
  const [intTaskNumber, setIntTaskNumber] = useState()
  const [roomNumbers, setRoomNumbers] = useState({})
  const [itemModalVisible, setItemModalVisible] = useState(false)
  const [deliveryAddress, setDeliveryAddress] = useState({})
  const [deliveryAddressVisible, setDeliveryAddressVisible] = useState()
  const [loadingAddress, setLoadingAddress] = useState({})
  const [loadingAddressVisible, setLoadingAddressVisible] = useState()
  const [copyOrderVisible, setCopyOrderVisible] = useState()
  const [statusses, setStatusses] = useState([])
  const [selectedStatus, setSelectedStatus] = useState()
  const [itemListError, setItemListError] = useState(false)
  const [priceList, setPriceList] = useState({})
  const [fetchController, setFetchController] = useState()
  const [itemsOutOfStock, setItemsOutOfStock] = useState([])
  const [costCenterList, setCostCenterList] = useState()
  const [isASAP, setIsASAP] = useState(false)
  const auth = useAuth()
  const uploadButton = (
    <Button icon={<UploadOutlined />}>{t('items.upload')}</Button>
  );

  const langID = LANGUAGE_ID_MAP[i18n.language]

  const handleChange = ({ fileList: newFileList }) => {
    setFileList(newFileList)
  };
  const beforeUpload = () => false

  const clearForm = () => {
    form.resetFields()
    setFileList([])
    setItemList([])
    setItemsOutOfStock([])
    setDeliveryAddress({})
    setLoadingAddress({})
    setSelectedStatus()
    setIsASAP(false)
  }

  const handlePreview = async (file) => {
    if (!file.strDocType || (!file.strDocType.startsWith('image') && !file.strDocType.startsWith('jpeg'))) {
      if (!file.type || !file.type.startsWith('image')) {
        return
      }
    }
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    setPreviewImage(file.url || file.preview);
    setPreviewVisible(file);
    setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
  };

  const handleCancel = () => {
    setPreviewVisible(false)
  };

  const handleDefaultDate = (date) => {
    const defaultDATE = '01.01.1900'

    if (typeof date === 'object') {
      if (date.isSame(defaultDATE)) {
        return true
      }
      return false
    }

  }

  const getPrices = (items) => {
    if (fetchController) {
      fetchController.abort()
      setFetchController()
    }
    const query = {
      strZIPFrom: loadingAddress['strZIP'],
      strZIPTo: deliveryAddress['strZIP'],
      strCityFrom: loadingAddress['strCity'],
      strCityTo: deliveryAddress['strCity'],
      strAddress1From: loadingAddress['strAddress1'],
      strAddress1To: deliveryAddress['strAddress1'],
      positions: JSON.stringify({
        items: items.map((item, index) => (
          {
            intPosition: index,
            intItemID: item.intItemID,
            decQty: item.decQty,
            intOrderTyp: +item.intOrderTyp
          }
        ))
      })
    }
    let url =
      'item/getItemPrice?' +
      Object.keys(query)
        .map(k => {
          return `${k}=${encodeURIComponent(query[k])}`
        })
        .join('&')
    if (url.includes('undefined')) {
      return
    }
    const controller = new AbortController()
    // signal to pass to fetch
    const signal = controller.signal
    setFetchController(controller)
    getFromAPI(url, {}, auth, signal)
      .then(res => res.json())
      .then(data => {
        const totalPrice = data.response?.reduce((a, b) => a + b.decAmount, 0)
        const response = data.response.map(item => ({ ...item, source: items[item.intPosition] }))
        setPriceList({ totalPrice, prices: response })
      })
  }

  const handleCopyOrder = (copyValues) => {
    if (copyValues) {
      clearForm()
      setLoadingData(true)
      const url = 'order/get?intOrderID=' + copyValues.intOrderID
      getFromAPI(url, {}, auth)
        .then(res => res.json())
        .then(data => {
          // Not copy executionDate if it is passed
          const executionDate = moment(data.response[0].dtmExecutionDate, "DD.MM.YYYY")
          const current = moment()
          if (executionDate < current) {
            delete data.response[0].dtmExecutionDate
          }
          fetchData(data.response[0])
        })
    }
    setCopyOrderVisible(false)
  }

  const fetchData = async (values) => {
    const updatedOrderTypes = await updateOrderTypes()
    const updatedOrderStatusses = await updateStatusses()
    const formValues = values

    const orderStatus = updatedOrderStatusses && updatedOrderStatusses.find(orderStatus => `${orderStatus.intValue}` === values['intOrderStatus'])
    if (orderStatus) {
      formValues['intOrderStatus'] = orderStatus.intValue
      setSelectedStatus(orderStatus.intValue)
    } else {
      formValues['intOrderStatus'] = undefined
    }

    if (formValues.Document) {
      const newFileList = []
      for (const document of formValues.Document) {
        const url = `data:image/jpeg;charset=utf-8;base64,${document.binDocument}`;
        const blobFile = await fetch(url).then(res => res.blob())
        const originFileObj = new File([blobFile], document.strTitle)
        const newFile = {}
        newFile.name = document.strTitle
        newFile.strDocType = document.strDocType
        newFile.intDocID = document.intDocID
        newFile.originFileObj = originFileObj
        newFileList.push(newFile)
      }
      setFileList(newFileList)
    } else {
      setFileList([])
    }
    formValues['intOrderID'] && editMode && setIntItemID(formValues['intOrderID'])
    formValues['intOrderNo'] && editMode && setIntOrderNo(formValues['intOrderNo'])
    formValues['intTaskNumber'] && editMode && setIntTaskNumber(formValues['intTaskNumber'])

    if (formValues['dtmExecutionDate']) {
      formValues['dtmExecutionDate'] = moment(formValues['dtmExecutionDate'], 'DD.MM.YYYY HH:mm:ss')

      if (handleDefaultDate(formValues['dtmExecutionDate'])) {
        delete formValues['dtmExecutionDate']
      }
    }
    if (formValues['dtmOrderDate'] && formValues['dtmExecutionDate']) {
      const orderDate = moment(formValues['dtmOrderDate'], 'DD.MM.YYYY HH:mm:ss')
      formValues['asap'] = formValues['dtmExecutionDate'].format('YYYY-MM-DD') === orderDate.format('YYYY-MM-DD')
      setIsASAP(formValues['asap'])
    }

    form.setFieldsValue(formValues)
    const quantities = {}
    if (formValues.Item && formValues.Item.length) {
      // Re-set keys to update the table
      const uniqueItems = formValues.Item.map(async item => {
        const url = 'item/get?intItemID=' + item.intItemID
        const fetchedItem = await getFromAPI(url, {}, auth)
          .then(res => res.json())
          .then(data => {
            return data.response && data.response[0]
          })
        let name
        if (fetchedItem) {
          name =
            fetchedItem.strName?.find(strName => strName.intLanguageID === langID) ||
            fetchedItem.strName?.find(
              strName => strName.intLanguageID === LANGUAGE_ID_DEFAULT
            ) || ''
        }
        const orderType = updatedOrderTypes && updatedOrderTypes.find(type => `${type.intValue}` === `${item.intOrderTyp}`)
        if (orderType) {
          const orderTypeName =
            orderType.strTypeName.find(strTypeName => strTypeName.intLanguageID === langID) ||
            orderType.strTypeName.find(
              strTypeName => strTypeName.intLanguageID === LANGUAGE_ID_DEFAULT
            )
          item.orderType = orderTypeName.strText
          if (item.intOrderTyp === 1) {
            if (quantities[item.intItemID]) {
              quantities[item.intItemID] += item.decQty
            } else {
              quantities[item.intItemID] = item.decQty
            }
          }

        }
        const returnItem = { ...item, ...fetchedItem, key: _uniqueId('prefix-'), name: name && name.strText }
        return returnItem
      })
      setItemList(await Promise.all(uniqueItems))
      setDefaultQuantities(quantities)
      setDeliveryAddress((formValues.Address && formValues.Address.find(address => address.intType === 2)) || {})
      setLoadingAddress((formValues.Address && formValues.Address.find(address => address.intType === 1)) || {})
    }
    setLoadingData(false)
    if (onReady) {
      onReady(true)
    }
  }

  useEffect(() => {
    clearForm()
    if (values) {
      setLoadingData(true)
      fetchData(values)
    }
  }, [values])

  const getRoomNumber = (item) => {
    const numbers = Object.keys(roomNumbers || {}).filter(key => roomNumbers[key]).map(key => roomNumbers[key])
    if (numbers?.length === 1) {
      return numbers[0]
    }
    return ['2', '5'].includes(`${item.intOrderTyp}`) ? roomNumbers.default : roomNumbers[1]
  }

  useEffect(() => {
    if (roomNumbers) {
      const newItemList = itemList.map(item => ({ ...item, strRoom: getRoomNumber(item) }))
      setItemList(newItemList)
    }
  }, [roomNumbers])

  useEffect(() => {
    if (itemList?.length) {
      getPrices(itemList)
    } else {
      setPriceList({})
    }
  }, [itemList])

  useEffect(() => {
    if (isASAP) {
      const orderDate = form.getFieldValue('dtmOrderDate')
      form.setFieldsValue({ dtmExecutionDate: orderDate ? moment(orderDate, 'DD.MM.YYYY HH:mm:ss') : moment() })
    }
  }, [isASAP])

  const [form] = useForm()

  const onFinish = async (inputValues) => {
    const formValues = { ...values, ...inputValues }
    setUpdatingOrder(true)
    if (intItemID && editMode) {
      formValues['intOrderID'] = intItemID
    }
    if (intOrderNo && editMode) {
      formValues['intOrderNo'] = intOrderNo
    }
    if (intTaskNumber && editMode) {
      formValues['intTaskNumber'] = intTaskNumber
    }
    if (!editMode) {
      formValues.intOrderStatus = 15
    }
    const documents = []
    for (const file of fileList) {
      let docItem = await getBase64(file.originFileObj);
      // Remove base64 config
      if (docItem.includes(',')) {
        const index = docItem.indexOf(',')
        docItem = docItem.substring(index + 1)
      }
      const document = { binDocument: docItem, strTitle: file.name, strDocType: file.type || file.strDocType }
      if (file.intDocID) {
        document.intDocID = file.intDocID
      }
      documents.push(document)
    }
    formValues['Document'] = documents
    const orderDeliveryAddress = { ...deliveryAddress, intType: 2 }
    delete orderDeliveryAddress['intAddressID']
    const orderLoadingAddress = { ...loadingAddress, intType: 1 }
    delete orderLoadingAddress['intAddressID']
    formValues['Address'] = [orderDeliveryAddress, orderLoadingAddress]
    const itemsOOO = []
    const quantities = {}
    formValues['Item'] = itemList.map((item, index) => {
      const orderItem = {}
      orderItem.intItemID = item.intItemID
      orderItem.intOrderDID = item.intOrderDID
      orderItem.decQty = item.decQty
      orderItem.decDuration = item.decDuration
      orderItem.strRoom = item.strRoom
      orderItem.intOrderTyp = item.intOrderTyp
      const price = priceList?.prices && priceList?.prices[index]?.decPrice || ''
      if (price) {
        orderItem.decPrice = price
      }
      const available = item.decQtyAvailable
      if (item.intOrderTyp === 1) {
        if (quantities[item.intItemID]) {
          quantities[item.intItemID].quantity += item.decQty
        } else {
          quantities[item.intItemID] = { quantity: item.decQty }
        }
        quantities[item.intItemID].available = available
      }

      return orderItem
    })
    Object.keys(quantities).forEach(id => {
      const item = quantities[id]
      let requestedQuantity = item.quantity
      if (defaultQuantities[id]) {
        requestedQuantity -= defaultQuantities[id]
      }
      if (item.available < requestedQuantity) {
        const found = itemList.find(value => value.intItemID === id)
        itemsOOO.push(found)
      }
    })
    delete formValues['deliveryAddress']
    delete formValues['loadingAddress']
    //formValues['dtmExecutionDate'] = formValues['dtmExecutionDate'] && formValues['dtmExecutionDate'].format('YYYY-MM-DDTHH:mm:ss')

    if (!editMode || (values.intOrderStatus === 14 && selectedStatus === 15)) { // add order date on changing 14 to 15
      formValues['dtmOrderDate'] = moment().utc().format('YYYY-MM-DDTHH:mm:ss[Z]')
    } else {
      try {
        formValues['dtmOrderDate'] = moment(formValues['dtmOrderDate'], 'DD.MM.YYYY HH:mm:ss').format('YYYY-MM-DDTHH:mm:ss[Z]')
      } catch (error) {

      }
    }
    delete formValues['itemListValidation']

    if (itemsOOO.length) {
      setItemsOutOfStock(itemsOOO)
      setUpdatingOrder(false)
      return
    }

    const url = editMode ? 'order/edit' : 'order/create'
    postToAPI(url, formValues, false, auth)
      .then(res => res.json())
      .then(data => {
        setUpdatingOrder(false)
        if (data.error) {
          openNotificationWithIcon('error', t('orders.onFail.title'),
            <>
              <>{t('orders.onFail.description')}</>
              <br />
              <div dangerouslySetInnerHTML={{ __html: data.response.Message || data.response }} />
            </>
          )
        }
        else {
          openNotificationWithIcon('success', t('orders.onSuccess.title'), t('orders.onSuccess.description'))
          clearForm()
        }
        if (onSubmit) {
          onSubmit(data)
        }
      })
      .catch(err => {
        openNotificationWithIcon('error', t('orders.onFail.title'), t('orders.onFail.description'))
        setUpdatingOrder(false)
      })
  }

  const getCostCenter = async () => {
    return await getFromAPI('order/getCostCenter', {}, auth).then(res => res.json()).then(data => {
      if (data.response) {
        setCostCenterList(alphaNumericSort(data.response));
      } else {
        setCostCenterList([]);
      }
    })
  }
  const updateOrderTypes = async () => {
    return await getFromAPI('type/getType?intParentID=1087', {}, auth).then(res => res.json()).then(data => {
      if (data.response) {
        const updatedOrderTypes = data.response.map((item, index) => {
          item.key = index
          return item
        })
        setOrderTypes(sortByLanguage(updatedOrderTypes, 'strTypeName'))
        return updatedOrderTypes
      }
    })
  }

  const updateStatusses = async () => {
    return await getFromAPI('type/getType?intParentID=1092', {}, auth).then(res => res.json()).then(data => {
      if (data.response) {
        const updatedStatusses = data.response.map((item, index) => {
          item.key = index
          return item
        })
        setStatusses(sortByLanguage(updatedStatusses, 'strTypeName'))
        return updatedStatusses
      }
    })
  }

  useEffect(() => {
    updateOrderTypes()
    updateStatusses()
  }, [])

  const handleAddItems = (items) => {
    // Remove common elements
    let union = _.unionWith(items, itemList, (item1, item2) =>
      item1.intItemID === item2.intItemID &&
      item1.intOrderTyp === item2.intOrderTyp &&
      item1.strRoom === item2.strRoom
    );
    // Find common elements and sum quantities
    const intersection = _.intersectionWith(itemList, items, (item1, item2) =>
      item1.intItemID === item2.intItemID &&
      item1.intOrderTyp === item2.intOrderTyp &&
      item1.strRoom === item2.strRoom
    );
    intersection.forEach(item => {
      const foundItem = union.find(unionItem => unionItem.intItemID === item.intItemID &&
        unionItem.intOrderTyp === item.intOrderTyp &&
        unionItem.strRoom === item.strRoom
      )
      foundItem.decQty += item.decQty
    })
    // Re-set keys to update the table
    const uniqueUnion = createUniqueItems(union)
    setItemList(uniqueUnion)
    setItemModalVisible(false)
    if (itemListError) {
      form.validateFields()
    }
  }

  const handleQtyChange = (record, value) => {
    const foundItem = itemList.find(item => item.intItemID === record.intItemID &&
      item.strRoom === record.strRoom &&
      item.intOrderTyp === record.intOrderTyp)
    foundItem.decQty = value
    // Re-set keys to update the table
    const uniqueItems = createUniqueItems(itemList)
    setItemList(uniqueItems)
  }

  const handleItemUpdate = (record, values) => {
    const foundItem = itemList.find(item => item.intItemID === record.intItemID &&
      item.strRoom === record.strRoom &&
      item.intOrderTyp === record.intOrderTyp)
    foundItem.decQty = values.decQty
    foundItem.strRoom = values.strRoom
    foundItem.intOrderTyp = values.intOrderTyp
    // Re-set keys to update the table
    const uniqueItems = createUniqueItems(itemList)
    setItemList(uniqueItems)
  }

  const handleRemoveItem = (record) => {
    const filteredItems = itemList.filter(item =>
      !(item.intItemID === record.intItemID &&
        item.strRoom === record.strRoom &&
        item.intOrderTyp === record.intOrderTyp)
    )
    const uniqueItems = createUniqueItems(filteredItems)
    setItemList(uniqueItems)
  }

  const createUniqueItems = (items) => {
    return items.map(item => {
      item.key = _uniqueId('prefix-')
      const orderType = orderTypes.find(type => `${type.intValue}` === `${item.intOrderTyp}`)
      if (orderType) {
        const orderTypeName =
          orderType.strTypeName.find(strTypeName => strTypeName.intLanguageID === langID) ||
          orderType.strTypeName.find(
            strTypeName => strTypeName.intLanguageID === LANGUAGE_ID_DEFAULT
          )
        item.orderType = orderTypeName.strText
      }
      return item
    })
  }

  const handleDeliveryAddress = address => {
    if (Object.keys(deliveryAddress).length) {
      setDeliveryAddress({ ...deliveryAddress, ...address })
    } else {
      setDeliveryAddress(address)
    }
    setRoomNumbers({ ...roomNumbers, 1: address['strAddress3'] })

    setDeliveryAddressVisible(false)
  }
  const handleLoadingAddress = address => {
    if (Object.keys(loadingAddress).length) {
      setLoadingAddress({ ...loadingAddress, ...address })
    } else {
      setLoadingAddress(address)
    }
    setLoadingAddressVisible(false)
    setRoomNumbers({ ...roomNumbers, default: address['strAddress3'] })
  }

  return (
    <Spin spinning={loadingData}>
      <FormWrapper disabled={previewMode || loadingData}>
        <OrderItemModal
          visible={itemModalVisible}
          onOk={handleAddItems}
          onCancel={() => setItemModalVisible(false)}
          roomNumbers={roomNumbers}
          orderTypes={orderTypes}
          priceList={priceList}
          reservation={selectedStatus === 14}
        // values={editItem || previewItem}
        // title={editItem ? t('items.editItem') : t('items.previewItem')} 
        />
        <OrderAddressModal
          visible={deliveryAddressVisible}
          onOk={handleDeliveryAddress}
          onCancel={() => setDeliveryAddressVisible(false)}
          values={deliveryAddress}
        />
        <OrderAddressModal
          visible={loadingAddressVisible}
          onOk={handleLoadingAddress}
          onCancel={() => setLoadingAddressVisible(false)}
          values={loadingAddress}
        />
        <OrderItemOutOfStockModal
          visible={itemsOutOfStock && itemsOutOfStock.length > 0}
          items={itemsOutOfStock}
          onCancel={() => setItemsOutOfStock([])}
        />
        <Form
          name="normal_edit_client"
          onFinish={onFinish}
          size="large"
          form={form}
          preserve={false}
          validateTrigger='onSubmit'
          labelCol={{ flex: '140px' }}
          labelWrap
          labelAlign="right"
          colon={false}
        >
          <Row className='middle-row'>
            <Col span={24} lg={{ span: 10, offset: 1 }}>
              <Row>
                <OrderNameContainer>
                  <OrderNameItem
                    name="strOrderTitle"
                    rules={[{ required: true, message: '' }]}
                    label={t('orders.title')}
                  >
                    <Input
                      type="text"
                    />
                  </OrderNameItem>
                  {
                    !editMode &&
                    <Button onClick={() => setCopyOrderVisible(true)}>
                      {t('orders.copyOrder')}
                    </Button>
                  }
                </OrderNameContainer>
              </Row>
              {editMode &&
                <Form.Item
                  name="intOrderStatus"
                  rules={[{ required: true, message: '' }]}
                  label={t('orders.status')}
                >
                  <Select
                    allowClear={false}
                    showSearch={false}
                    placeholder={t('orders.selectPlaceholder')}
                    optionFilterProp="children"
                    style={{ minWidth: 150 }}
                    dropdownMatchSelectWidth={false}
                    onSelect={setSelectedStatus}
                    disabled={selectedStatus > 15}
                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                  >
                    {statusses.filter(item => selectedStatus > 15 || (item.intValue === 14 || item.intValue === 15)).map((orderType, index) => {
                      const text = orderType.strTypeName.find(name => name.intLanguageID === langID)
                        || orderType.strTypeName.find(name => name.intLanguageID === LANGUAGE_ID_DEFAULT)
                      return <Option key={index} value={orderType.intValue}>{text ? text.strText : `${orderType.intValue}`}</Option>
                    })}
                  </Select>
                </Form.Item>
              }
              <Form.Item
                name="strCustomerOrderNo1"
                rules={[{ required: selectedStatus !== 14, message: '' }]}
                label={t('orders.strCustomerOrderNo')}
              >
                <Input
                  type="text"
                />
              </Form.Item>
              <Form.Item
                name="strCostCenter"
                rules={[{ required: false, message: '' }]}
                label={t('orders.costCenter')}
              >
                <AutoComplete
                  options={costCenterList || []}
                  filterOption={(inputValue, option) =>
                    option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                  }
                  onFocus={costCenterList === undefined ? getCostCenter : undefined}
                  listHeight={320}
                />
              </Form.Item>

              <Form.Item
                name="strContacPerson"
                rules={[{ required: selectedStatus !== 14, message: '' }]}
                label={t('orders.contact')}
              >
                <Input
                  type="text"
                />
              </Form.Item>

              <Form.Item
                name="strContacTel"
                rules={[{ required: selectedStatus !== 14, message: '' }]}
                label={t('orders.phone')}
              >
                <PhoneInput />
              </Form.Item>

              <Form.Item
                name="strContactMobile"
                label={t('orders.mobile')}
              >
                <PhoneInput />
              </Form.Item>
              <Form.Item
                name="strContactEMail"
                label={t('orders.email')}
                rules={[{ required: selectedStatus !== 14, message: '' }, { type: 'email', message: t('users.notValidEmail') }]}
              >
                <Input
                  type="text"
                />
              </Form.Item>
              <Form.Item
                label={t('orders.loadingAddress')}
                name="loadingAddress"
                rules={[
                  {
                    message: t('orders.addressWarning'),
                    required: selectedStatus !== 14,
                    validator: (n, value) => {
                      if (!_.isEmpty(loadingAddress) || selectedStatus === 14) {
                        return Promise.resolve();
                      } else {
                        return Promise.reject();
                      }
                    }
                  }
                ]}
              >
                <AddressDisplay>
                  <Button onClick={() => setLoadingAddressVisible(true)}>
                    {t('orders.selectAddress')}
                  </Button>
                  {loadingAddress &&
                    <span>
                      <p>{loadingAddress.strName}</p>
                      <p>{loadingAddress.strAddress1}</p>
                      <p>{loadingAddress.strZIP}&nbsp;{loadingAddress.strCity}</p>
                      <p>{loadingAddress.strCountry}</p>
                    </span>
                  }
                </AddressDisplay>
              </Form.Item>
              <Form.Item
                label={t('orders.deliveryAddress')}
                name="deliveryAddress"
                rules={[
                  {
                    message: t('orders.addressWarning'),
                    required: selectedStatus !== 14,
                    validator: (n, value) => {
                      if (!_.isEmpty(deliveryAddress) || selectedStatus === 14) {
                        return Promise.resolve();
                      } else {
                        return Promise.reject();
                      }
                    }
                  }
                ]}
              >
                <AddressDisplay>
                  <Button onClick={() => setDeliveryAddressVisible(true)}>
                    {t('orders.selectAddress')}
                  </Button>
                  {deliveryAddress &&
                    <span>
                      <p>{deliveryAddress.strName}</p>
                      <p>{deliveryAddress.strAddress1}</p>
                      <p>{deliveryAddress.strZIP}&nbsp;{deliveryAddress.strCity}</p>
                      <p>{deliveryAddress.strCountry}</p>
                    </span>
                  }
                </AddressDisplay>
              </Form.Item>


              <Modal
                visible={previewVisible}
                title={previewTitle}
                onCancel={handleCancel}
                footer={null}
                destroyOnClose
              >
                <img
                  alt="example"
                  style={{
                    width: '100%',
                  }}
                  src={previewImage}
                />
              </Modal>
            </Col>

            <Col className='align-right' span={24} lg={{ span: 11, offset: 1 }}>
              {
                selectedStatus !== 14 && (
                  <Form.Item name='asap' valuePropName='checked'>
                    <StyledCheckbox onChange={e => setIsASAP(e.target.checked)}>
                      {t('orders.asap')}
                    </StyledCheckbox>
                  </Form.Item>
                )
              }
              <Form.Item
                name="dtmExecutionDate"
                label={<Space>
                  <span>{t('orders.execution')}</span>
                  <Tooltip content={t('orders.executionTooltip')} placement='top' />
                </Space>}
                rules={[{ required: !isASAP && (selectedStatus !== 14), message: '' }]}
              >
                <DatePicker
                  style={{ width: '100%' }}
                  format='DD.MM.YYYY'
                  placeholder={t('orders.selectDate')}
                  showToday={false}
                  disabled={isASAP}
                  disabledDate={(current) => {
                    // Disable selecting past dates
                    const customDate = moment().add(1, 'days').format("YYYY-MM-DD");
                    return current && current < moment(customDate, "YYYY-MM-DD");
                  }}
                />
              </Form.Item>

              <Form.Item
                name="strDescription"
                label={t('orders.description')}
              >
                <Input.TextArea
                  type="text"
                  style={{ height: 298 }}
                />
              </Form.Item>

              <div
                style={{ pointerEvents: 'all' }}
                className='align-upload-right'
              >
                <Upload
                  fileList={fileList}
                  onPreview={handlePreview}
                  beforeUpload={beforeUpload}
                  onChange={handleChange}
                  disabled={previewMode}
                  multiple
                  accept={'image/*, .pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document'}
                >
                  {!previewMode && uploadButton}
                </Upload>
              </div>
            </Col>
          </Row>

          <Row justify="space-between">
            <Col span={24} lg={{ span: 22, offset: 1 }} style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography.Paragraph style={{ alignSelf: 'end' }}>{priceList?.totalPrice ? `${t('orders.totalPrice')} : ${priceList?.totalPrice.toFixed(2)} CHF` : ''}</Typography.Paragraph>
              {!previewMode && <Form.Item>
                <Button onClick={() => setItemModalVisible(true)}>
                  {t('orders.addItem')}
                </Button>
              </Form.Item>}
            </Col>
          </Row>
          <Row>
            <Col span={24} lg={{ span: 22, offset: 1 }}>
              <Form.Item
                name="itemListValidation"
                rules={[
                  {
                    message: t('orders.itemWarning'),
                    required: true,
                    validator: (n, value) => {
                      if (!_.isEmpty(itemList)) {
                        setItemListError(false)
                        return Promise.resolve();
                      } else {
                        setItemListError(true)
                        return Promise.reject();
                      }
                    }
                  }
                ]}
              >
                <OrderItemTable
                  dataSource={itemList}
                  reservation={selectedStatus === 14}
                  onQtyChange={handleQtyChange}
                  onRemoveItem={handleRemoveItem}
                  style={{ border: itemListError && 'solid 1px red' }}
                  onItemUpdate={handleItemUpdate}
                  priceList={priceList}
                  orderTypes={orderTypes}
                  roomNumbers={roomNumbers} />
              </Form.Item>
            </Col>
          </Row>
          {!previewMode &&
            <Row justify="center">
              <Col span={24} lg={22}>
                <Row justify="end">
                  <Form.Item>
                    <Button htmlType="submit" disabled={updatingOrder}>
                      {editMode ? (buttonText || t('orders.confirmChanges')) : t('orders.placeOrder')}
                    </Button>
                  </Form.Item>
                </Row>
              </Col>
            </Row>
          }
        </Form>
        {
          !editMode &&
          <CopyOrderModal visible={copyOrderVisible} onCancel={() => setCopyOrderVisible(false)} onOk={handleCopyOrder} />
        }
      </FormWrapper>
    </Spin>
  )
}
const FormWrapper = styled.div`
  .ant-modal-body {
    height: fit-content;
    @media ${device.tablet} {
      height: initial;
    }
  }
  pointer-events: ${props => props.disabled && 'none'};
  opacity: ${props => props.disabled && '0.7'};
  .ant-tabs-nav-wrap {
    @media ${device.tablet} {
      margin-left: 120px;
    }
  }
  .ant-table-pagination{
    opacity: 1 !important;
    pointer-events: all !important;
  }
  .align-right .ant-form-item-control-input-content, .align-upload-right > span {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  .middle-row {
    margin-bottom: 20px;
    @media ${device.tabletL} {
      margin-bottom: 0;
    }
  }
`
const AddressDisplay = styled.div`
  display: flex;
  p {
    margin-bottom: 2px;
  }
  gap: 15px;
`

const OrderNameContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: flex-end;
  margin-bottom: 19px;
  button {
    margin-bottom: 5px;
  }
  .ant-form-item-explain {
    min-height: 0;
  }
`
const OrderNameItem = styled(Form.Item)`
  flex: 1;
  min-width: 290px;
  margin-bottom: 5px;
`

const StyledCheckbox = styled(Checkbox)`
  flex-direction: row-reverse;
`
export default OrderForm;
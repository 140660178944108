import React, { useState } from 'react'
import { Modal } from 'antd';
import styled from 'styled-components';
import { device } from '../../style/breakpoints/device';
import _uniqueId from 'lodash/uniqueId';
import { useTranslation } from 'react-i18next';
import { Orders } from '../../pages/Orders/Orders';

export const CopyOrderModal = (props) => {
  const { visible, onCancel, onOk } = props
  const { t } = useTranslation()

  const handleCancel = () => {
    if (onCancel) {
      onCancel()
    }
  }

  const handleOrderSelect = (order) => {
    if (onOk) {
      onOk(order)
    }
  }

  return (
    <ModalWrapper
      title={t('orders.copyOrder')}
      visible={visible}
      onCancel={handleCancel}
      footer={null}
      destroyOnClose
      width={1024}
      wrapClassName={"large-content"}
    >
      <Orders selectorMode onSelect={handleOrderSelect} />
    </ModalWrapper>
  )
}
const ModalWrapper = styled(Modal)`
  .ant-modal-body {
    height: fit-content;
    @media ${device.tablet} {
      height: initial;
    }
  }
`

import React from 'react';
import styled from 'styled-components'
import { device } from '../../style/breakpoints/device'
import { Button, Checkbox } from 'antd'
import { useTranslation } from 'react-i18next'
import { useCookieConsent } from '../../context/CookieConsentProvider';
import { COOKIE_OPTIONS } from '../../utils/Constants'

const CheckboxGroup = Checkbox.Group;

export const CookieConsentForm = () => {

  const { t } = useTranslation()

  const { handleDecline, handleConsent, handleSelectedConsent, consentGiven, onOptionsChange, checkedList} = useCookieConsent()

  const cookieOptions = COOKIE_OPTIONS.map(item => {
    return {
      label: t(`cookieConsent.cookieOptions.${item.toLowerCase()}`),
      value: item,
      disabled: item === 'Essential'
    }
  })

  if(consentGiven) return null;

  return (
    <CookieConsentLayout>
      <Container>
        <StyledText>{t('cookieConsent.description')}</StyledText>
        <StyledLink href='https://www.welti-furrer.ch/impressum-haftungsausschluss/#Datenschutz' target='_blank' rel='noreferrer'>{t('cookieConsent.dataPrivacyText')}</StyledLink>
        <StyledCheckboxGroup options={cookieOptions} value={checkedList} onChange={onOptionsChange} />
      </Container>
      <ButtonWrapper>
        <StyledButton onClick={handleSelectedConsent}>{t('cookieConsent.button.acceptSelected')}</StyledButton>
        <StyledButton onClick={handleConsent}>{t('cookieConsent.button.acceptAll')}</StyledButton>
        <StyledButton onClick={handleDecline}>{t('cookieConsent.button.decline')}</StyledButton>
      </ButtonWrapper>
    </CookieConsentLayout>
  );
};


const CookieConsentLayout = styled.div`
  align-items: baseline;
  background-color: ${props => props.theme.colors.secondary};
  color: ${props => props.theme.colors.fontColorSecondary};
  display: flex;
  justify-content: space-between;
  left: 0px;
  position: fixed;
  width: 100%;
  z-index: 999;
  bottom: 0px;
  padding: 20px;
  flex-direction: column;
  box-shadow: 0px 0px 10px 0px rgba(200,200,200,0.75);

`

const Container = styled.div`
  max-width: 100%;
  @media ${device.laptopL} {
    max-width: 98%;
  }
`

const StyledText = styled.p`
  color: ${props => props.theme.colors.fontColorSecondary};
  margin: 0;
  padding: 0;
  font-size: 14px;
  display: inline;
  @media ${device.tablet} {
    font-size: 16px;
  }
`

const StyledLink = styled.a`
  text-decoration: underline;
  color: ${props => props.theme.colors.fontColorSecondary};
  margin-left: 4px;
  font-size: 16px;
`

const StyledCheckboxGroup = styled(CheckboxGroup)`
  margin-top: 20px;
  display: block;
  .ant-checkbox-wrapper, .ant-checkbox-disabled + span {
    color: ${props => props.theme.colors.fontColorSecondary};
  }
`

const ButtonWrapper = styled.div`
  gap: 15px;
  display: flex;
  align-self: center;
  align-self: flex-end;
  margin-top: 20px;
  flex-wrap: wrap;
  @media ${device.tablet} {
    margin-top: 0;
  }
`

const StyledButton = styled(Button)`
`

import { createGlobalStyle } from 'styled-components'

import { colors } from './colors'
import { device } from '../../breakpoints/device'

import 'antd/dist/antd.css'; // or 'antd/dist/antd.less'
import 'react-quill/dist/quill.snow.css';

export const defaultTheme = {
  colors: colors
}

export const GlobalStyles = createGlobalStyle`
    body {
      background-color: #F0F2F5;
      color: ${props => props.theme.colors.fontColor};
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-size: 14px;
      @media ${device.laptopL} {
        font-size: 16px;
      }
      margin: 0px;
      padding: 0px;
    }
    .ant-input-affix-wrapper:hover {
      border-color: ${props => props.theme.colors.primary} !important;
    }
    .ant-input-affix-wrapper-focused {
      border-color: ${props => props.theme.colors.primary} !important;
      box-shadow: none;
    }
    .large-content .ant-modal {
      @media ${device.tablet} {
        width: max-content !important;
        max-width: 90vw !important;
      }
      @media ${device.laptop} {
        min-width: 1024px;
      }
    }
    .ant-modal {
      width: 100vw;
      margin: 0;
      top: 0;
      @media ${device.tablet} {
        height: initial;
        width: initial;
        margin: 0 auto;
        top: 100px;
      }
    }
    .ant-modal-content {
      width: 100vw;
      margin: 0;
      top: 0;
      @media ${device.tablet} {
        height: initial;
        width: initial;
        margin: initial;
        top: initial;
      }
    }
    .ant-modal-body {
      height: calc(100vh - 110px);
      @media ${device.tablet} {
        height: initial;
      }
    }
    .chrome-picker { 
      margin: -12px -16px;
    }
    .ant-btn {
      &:hover {
        color: ${props => props.theme.colors.primary};
        border-color: ${props => props.theme.colors.primary};
      }
      &:focus {
        color: #000;
        border-color: ${props => props.theme.colors.primary};
      }
    }
    .ql-snow .ql-tooltip {
      z-index: 1;
    }
`

import React, { useEffect, useState } from 'react'
import { Button, Form, Modal, Row, Select } from 'antd';
import styled from 'styled-components';
import { device } from '../../style/breakpoints/device';
import { AddressForm } from '../Form/AddressForm';
import { useTranslation } from 'react-i18next';
import { getFromAPI, postToAPI } from '../../context/API';
import { useForm } from 'antd/lib/form/Form';
import { useAuth } from '../../context/AuthProvider';
import { sortByLanguage } from '../../utils/DataUtils';
import { openNotificationWithIcon } from '../../utils/Notification';


const { Option } = Select;


export const OrderAddressModal = (props) => {
  const { visible, onCancel, title, values, editMode, onSubmit, onOk } = props
  const { t } = useTranslation();
  const [addresses, setAddresses] = useState([])
  const [selectedAddress, setSelectedAddress] = useState(values)
  const [initialFormValues, setInitialFormValues] = useState()
  const [isChanged, setIsChanged] = useState(false)
  const [form] = useForm()
  const auth = useAuth()

  const updateAddresses = async () => {
    return await getFromAPI('address/get', {}, auth).then(res => res.json()).then(data => {
      if (data.response) {
        const updatedAddresses = data.response.map((item, index) => {
          item.key = index
          return item
        })
        setAddresses(sortByLanguage(updatedAddresses, 'strName'))
        return updatedAddresses
      }
    })
  }

  useEffect(() => {
    updateAddresses()
  }, [])

  useEffect(() => {
    setSelectedAddress(values)
  }, [visible])

  const handleOk = () => {
    const address = form.getFieldsValue()
    if (onOk) {
      onOk(address)
    }
  }

  const handleSave = () => {
    const formValues = form.getFieldsValue()
    const link = 'address/create'
    setIsChanged(false)
    postToAPI(link, formValues, false, auth)
      .then(res => res.json())
      .then(data => {
        if (onSubmit) {
          onSubmit(data)
        }
        openNotificationWithIcon(
          'success',
          t('addresses.onSuccess.title'),
          t('addresses.onSuccess.description')
        )
        setIsChanged(false)
        setInitialFormValues(formValues)
        updateAddresses()
      })
      .catch(err => {
        setIsChanged(true)
        openNotificationWithIcon(
          'error',
          t('addresses.onFail.title'),
          t('addresses.onFail.description')
        )
      })
  }

  const handlePropsChange = () => {
    setInitialFormValues(form.getFieldsValue())
  }

  const handleChange = () => {
    setIsChanged(JSON.stringify(initialFormValues) !== JSON.stringify(form.getFieldsValue()))
  }

  const handleSelectAddress = (intAddressID) => {
    const foundAdress = addresses.find(address => address.intAddressID === intAddressID)
    setSelectedAddress(foundAdress)
  }
  return (
    <ModalWrapper
      title={title}
      visible={visible}
      onCancel={onCancel}
      onOk={handleOk}
      footer={[
        <Button
          key='save'
          onClick={handleSave}
          disabled={!isChanged}
          style={{ marginRight: 'auto' }}
        >
          {t('orders.addToAddressList')}
        </Button>,
        <Button key="cancel" onClick={onCancel}>
          {t('modals.cancel')}
        </Button>,
        <Button key="submit" onClick={handleOk}>
          {t('modals.ok')}
        </Button>
      ]}
      destroyOnClose
    >
      <Row style={{ marginBottom: 24, marginTop: 24, display: 'flex', flexWrap: 'wrap' }}>
        <FormWrapper>
          <Form
            size="large"
            preserve={false}
            labelCol={{ flex: '140px' }}
            labelAlign='right'
            colon={false}
          >
            <Form.Item
              name='strName'
              label={t('orders.copyAddress')}
              style={{ marginBottom: 0 }}
            >
              <Select
                showSearch
                allowClear
                size='large'
                placeholder={t('orders.selectPlaceholder')}
                optionFilterProp="children"
                style={{ width: '100%' }}
                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                onSelect={handleSelectAddress}
                onClear={() => { form.resetFields() }}
              >
                {addresses.map((address, index) => {
                  const name = [
                    address.strBuildungCode,
                    address.strName,
                    address.strFirstName,
                    address.strAddress1,
                    address.strAddress2,
                    address.strAddress3,
                    address.strZIP,
                    address.strCity,
                    address.strCountry
                  ].filter(item => item).join(', ')
                  return <Option key={index} value={address.intAddressID}>{name}</Option>
                })}
              </Select>
            </Form.Item>
          </Form>
        </FormWrapper>
      </Row>
      <AddressForm editMode={editMode} values={selectedAddress} onSubmit={onSubmit} selectorMode form={form} onPropsChange={handlePropsChange} onChange={handleChange} />
    </ModalWrapper>
  )
}
const ModalWrapper = styled(Modal)`
  .ant-modal-body {
    height: fit-content;
    @media ${device.tablet} {
      height: initial;
    }
  }
  .ant-modal-footer{
    display: flex;
    justify-content: flex-end;
  }
`

const FormWrapper = styled.div`
  width: 100%;
`

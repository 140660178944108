import React, { useState } from 'react'
import { Modal } from 'antd';
import styled from 'styled-components';
import { device } from '../../style/breakpoints/device';
import { Items } from '../../pages/Items/Items';
import { OrderItemTable } from '../Table/OrderItemTable';
import _uniqueId from 'lodash/uniqueId';
import { useTranslation } from 'react-i18next';
import OrderItemQuantityModal from './OrderItemQuantityModal';
import { openNotificationWithIcon } from '../../utils/Notification';
import { LANGUAGE_ID_DEFAULT, LANGUAGE_ID_MAP } from '../../utils/Constants';
import i18n from '../../i18n';

const langID = LANGUAGE_ID_MAP[i18n.language]

export const OrderItemModal = (props) => {
  const { visible, onCancel, title, onOk, orderTypes, reservation, roomNumbers } = props
  const { t } = useTranslation()
  const [items, setItems] = useState([])
  const [selectedItem, setSelectedItem] = useState()

  const handleOk = () => {
    if (onOk) {
      onOk(items)
    }
    setItems([])
  }

  const handleCancel = () => {
    if (onCancel) {
      onCancel()
    }
    setItems([])
  }
  
  const getRoomNumber = (addedItem) => {
    let key = 1
    const numbers = Object.keys(roomNumbers || {}).filter(key => roomNumbers[key])
    if (numbers?.length === 1) {
      key = numbers[0]
    }
    return roomNumbers ? roomNumbers[key] : addedItem.strRoom 
  }

  const handleItemSelect = (addedItem) => {
    const newItem = { ...addedItem, strRoom: getRoomNumber(addedItem) }
    setSelectedItem(newItem)
  }

  const handleItemQuantity = addedItem => {
    let foundItem = false
    addedItem.orderType = getOrderTypeFromId(addedItem.intOrderTyp)
    const updatedItems = items.map((item) => {
      item.key = _uniqueId('prefix-')
      item.orderType = getOrderTypeFromId(item.intOrderTyp)
      if (!item.decQty) {
        item.decQty = 1
      }
      if (item.intItemID === addedItem.intItemID &&
        item.strRoom === addedItem.strRoom &&
        item.intOrderTyp === addedItem.intOrderTyp) {
        item.decQty += addedItem.decQty
        foundItem = true
      }
      return item
    })
    if (!foundItem) {
      updatedItems.unshift(addedItem)
    }
    setItems(updatedItems)
    setSelectedItem()
    openNotificationWithIcon(
      'success',
      t('orders.amountOnSuccess.title'),
      t('orders.amountOnSuccess.description')
    )
  }

  const getOrderTypeFromId = (intOrderTyp) => {
    const orderType = orderTypes.find(type => `${type.intValue}` === `${intOrderTyp}`)
    if (orderType) {
      const orderTypeName =
        orderType.strTypeName.find(strTypeName => strTypeName.intLanguageID === langID) ||
        orderType.strTypeName.find(
          strTypeName => strTypeName.intLanguageID === LANGUAGE_ID_DEFAULT
        )
      return orderTypeName.strText
    }
  }

  const handleRemoveItem = (record) => {
    const filteredItems = items.filter(item =>
      !(item.intItemID === record.intItemID &&
        item.strRoom === record.strRoom &&
        item.intOrderTyp === record.intOrderTyp)
    )
    const uniqueItems = filteredItems.map(item => {
      item.key = _uniqueId('prefix-')
      return item
    })
    setItems(uniqueItems)
  }

  const createUniqueItems = (items) => {
    return items.map(item => {
      item.key = _uniqueId('prefix-')
      const orderType = orderTypes.find(type => `${type.intValue}` === `${item.intOrderTyp}`)
      if (orderType) {
        const orderTypeName =
          orderType.strTypeName.find(strTypeName => strTypeName.intLanguageID === langID) ||
          orderType.strTypeName.find(
            strTypeName => strTypeName.intLanguageID === LANGUAGE_ID_DEFAULT
          )
        item.orderType = orderTypeName.strText
      }
      return item
    })
  }

  const handleItemUpdate = (record, values) => {
    const foundItem = items.find(item => item.intItemID === record.intItemID &&
      item.strRoom === record.strRoom &&
      item.intOrderTyp === record.intOrderTyp)
    foundItem.decQty = values.decQty
    foundItem.strRoom = values.strRoom
    foundItem.intOrderTyp = values.intOrderTyp
    // Re-set keys to update the table
    const uniqueItems = createUniqueItems(items)
    setItems(uniqueItems)
  }

  return (
    <ModalWrapper
      title={title}
      visible={visible}
      onOk={handleOk}
      onCancel={handleCancel}
      okText={t('modals.ok')}
      cancelText={t('modals.cancel')}
      destroyOnClose
      width={1024}
      wrapClassName={"large-content"}
    >
      <h3>{t('items.allItems')}</h3>
      <Items selectorMode onSelect={handleItemSelect} />
      {
        items.length > 0 &&
        <>
          <h3>{t('items.selectedItems')}</h3>
          <OrderItemTable
            dataSource={items}
            reservation={reservation}
            onRemoveItem={handleRemoveItem}
            onItemUpdate={handleItemUpdate}
            orderTypes={orderTypes} />
        </>
      }
      <OrderItemQuantityModal
        title={t('orders.addItem')}
        okText={t('orders.addItem')}
        cancelText={t('modals.cancel')}
        visible={selectedItem}
        values={selectedItem}
        orderTypes={orderTypes}
        reservation={reservation}
        roomNumbers={roomNumbers}
        onCancel={() => setSelectedItem()}
        onOk={(values) => handleItemQuantity(values)}
      />
    </ModalWrapper>
  )
}
const ModalWrapper = styled(Modal)`
  .ant-modal-body {
    height: fit-content;
    @media ${device.tablet} {
      height: initial;
    }
  }
  h3 {
    margin-top: 15px;
    margin-bottom: 25px;
    @media ${device.tablet} {
      margin-top: 25px;
      margin-bottom: 45px;
    }
  }
`

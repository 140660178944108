import React from 'react'
import { Modal } from 'antd';
import styled from 'styled-components';
import { device } from '../../style/breakpoints/device';
import { useTranslation } from 'react-i18next';

export const ActivityWarningModal = (props) => {
  const { visible, afterClose } = props
  const { t } = useTranslation()
  return (
    <ModalWrapper
      title={t('warnings.inactivity.title')}
      visible={visible}
      onCancel={afterClose}
      destroyOnClose
      footer={null}
    >
      {t('warnings.inactivity.description')}
    </ModalWrapper>
  )
}
const ModalWrapper = styled(Modal)`
  .ant-modal-body {
    height: fit-content;
    @media ${device.tablet} {
      height: initial;
    }
  }
`

import { useForm } from 'antd/lib/form/Form'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { device } from '../../style/breakpoints/device'
import { Button, Col, Form, Input, Row, InputNumber, Select } from 'antd'
import { useTranslation } from 'react-i18next'
import { getFromAPI, postToAPI } from '../../context/API'
import { openNotificationWithIcon } from '../../utils/Notification'
import { useAuth } from '../../context/AuthProvider'
import { sortByLanguage } from '../../utils/DataUtils'
import PhoneInput from '../Input/PhoneInput'

const { Option } = Select

export const UserForm = props => {
  const { values, editMode, onSubmit } = props
  const [form] = useForm()
  const { t } = useTranslation()
  const auth = useAuth()
  const [roles, setRoles] = useState([])
  const [updatingUser, setUpdatingUser] = useState(false)

  const onFinish = formValues => {
    setUpdatingUser(true)
    if (editMode) {
      formValues['UserId'] = values['UserId']
    }
    const url = editMode ? 'auth/updateUser' : 'auth/register'
    postToAPI(url, formValues, false, auth)
      .then(res => res.json())
      .then(data => {
        setUpdatingUser(false)
        if (data.code === 400) {
          const failMessage =
            <>
              <p>{t('users.onFail.description')}</p>
              {data.response.ModelState[""].map((msg, index) => {
                return <p key={index}>{msg}</p>
              })}
            </>
          openNotificationWithIcon(
            'error',
            t('users.onFail.title'), failMessage
          )
        } else {
          if (onSubmit) {
            onSubmit(data)
          }
        }
      })
      .catch(err => {
        setUpdatingUser(false)
        openNotificationWithIcon(
          'error',
          t('users.onFail.title'),
          t('users.onFail.description')
        )
      })
  }

  useEffect(() => {
    const fetchData = async () => {
      const updatedRoles = await updateRoles()
      const selectedRole = updatedRoles && updatedRoles.find(
        role =>
          role.intRoleID === values['intRoleID']
      )
      if (selectedRole) {
        values['intRoleID'] = selectedRole.intRoleID
      } else {
        values['intRoleID'] = undefined
      }
      form.setFieldsValue(values)
    }
    if (Object.keys(values).length > 0) {
      fetchData()
    }
  }, [values])

  useEffect(() => {
    updateRoles()
  }, [])

  const updateRoles = async () => {
    return await getFromAPI('auth/getRoles', {}, auth)
      .then(res => res.json())
      .then(data => {
        if (data.response) {
          const updatedRoles = data.response.map((item, index) => {
            item.key = index
            return item
          })
          setRoles(sortByLanguage(updatedRoles, 'strRoleName'))
          return updatedRoles
        }
      })
  }

  return (
    <FormWrapper>
      <Form
        name='normal_edit_user'
        onFinish={onFinish}
        size='large'
        form={form}
        preserve={false}
        validateTrigger='onSubmit'
        labelCol={{ flex: '150px' }}
        labelAlign='right'
        colon={false}
      >
        <Form.Item name='Email' label={t('users.email')}
          rules={[
            {
              type: 'email',
              message: t('users.notValidEmail')
            },
          ]}
        >
          <Input type='text' />
        </Form.Item>
        {
          !editMode &&
          <>
            <Form.Item
              name='password'
              label={t('users.password')}
              rules={[{ required: true, message: '' }]}>
              <Input
                type='password'
              />
            </Form.Item>
            <Form.Item
              name='confirmPassword'
              label={t('users.confirmPassword')}
              rules={[
                {
                  required: true,
                  message: '',
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error(''));
                  },
                }),
              ]}>
              <Input
                type='password'
              />
            </Form.Item>
          </>
        }
        <Form.Item
          name='strFirstName'
          rules={[{ required: true, message: '' }]}
          label={t('users.firstName')}
        >
          <Input type='text' />
        </Form.Item>
        <Form.Item name='strName'
          rules={[{ required: true, message: '' }]}
          label={t('users.surname')}
        >
          <Input type='text' />
        </Form.Item>
        <Form.Item name='intRoleID' label={t('users.role')}>
          <Select
            showSearch
            allowClear
            placeholder={t('items.selectPlaceholder')}
            optionFilterProp='children'
            style={{ minWidth: 150 }}
            dropdownMatchSelectWidth={false}
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
          >
            {roles && roles.map((role, index) => {
              return (
                <Option key={index} value={role.intRoleID}>
                  {role.strRoleName}
                </Option>
              )
            })}
          </Select>
        </Form.Item>
        <Form.Item
          name='strStreet'
          label={t('users.street')}
        >
          <Input type='text' />
        </Form.Item>
        <Form.Item
          name='strZIP'
          label={t('users.zip')}
        >
          <InputNumber type="number" style={{ width: '100%' }} />
        </Form.Item>
        <Form.Item
          name='strCity'
          label={t('users.city')}
        >
          <Input type='text' />
        </Form.Item>
        <Form.Item name='strState' label={t('users.state')}>
          <Input type='text' />
        </Form.Item>
        <Form.Item name='strCountry' label={t('users.country')}>
          <Input type='text' />
        </Form.Item>
        <Form.Item name='strPhone' label={t('users.phone')}>
          <PhoneInput />
        </Form.Item>
        <Form.Item name='strMobile' label={t('users.mobile')}>
          <PhoneInput />
        </Form.Item>
        <Row justify='center'>
          <Col span={24}>
            <Row justify='end'>
              <Form.Item>
                <Button htmlType='submit' disabled={updatingUser}>
                  {editMode
                    ? t('users.confirmChanges')
                    : t('users.addUser')}
                </Button>
              </Form.Item>
            </Row>
          </Col>
        </Row>
      </Form>
    </FormWrapper>
  )
}

const FormWrapper = styled.div`
  .ant-modal-body {
    height: fit-content;
    @media ${device.tablet} {
      height: initial;
    }
  }
`

import { useForm } from 'antd/lib/form/Form'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { device } from '../../style/breakpoints/device'
import { Button, Col, Form, Input, Row, Tabs, notification } from 'antd'
import { useTranslation } from 'react-i18next'
import { postToAPI } from '../../context/API'
import { openNotificationWithIcon } from '../../utils/Notification'
import { useAuth } from '../../context/AuthProvider'
import PhoneInput from '../Input/PhoneInput'

export const AddressForm = (props) => {
  const { values, editMode, onSubmit, selectorMode, form, onPropsChange, onChange } = props
  const [updatingAddress, setUpdatingAddress] = useState(false)
  const [addressForm] = useForm(form)
  const { t } = useTranslation();
  const auth = useAuth()

  const onFinish = formValues => {
    setUpdatingAddress(true)
    if (editMode) {
      formValues['intAddressID'] = values['intAddressID']
    }
    const link = editMode ? 'address/edit' : 'address/create'
    postToAPI(link, formValues, false, auth)
      .then(res => res.json())
      .then(data => {
        setUpdatingAddress(false)
        if (onSubmit) {
          onSubmit(data)
        }
      })
      .catch(err => {
        setUpdatingAddress(false)
        openNotificationWithIcon(
          'error',
          t('addresses.onFail.title'),
          t('addresses.onFail.description')
        )
      })
  }

  useEffect(() => {
    if (Object.keys(values).length > 0) {
      addressForm.setFieldsValue(values)
      if (onPropsChange) {
        onPropsChange()
      }
    }
  }, [values])

  return (
    <FormWrapper>
      <Form
        name='normal_edit_manufacturer'
        onFinish={onFinish}
        size="large"
        form={addressForm}
        preserve={false}
        validateTrigger='onSubmit'
        labelCol={{ flex: '140px' }}
        labelAlign='right'
        colon={false}
        onChange={onChange}
      >
        <Form.Item
          name='strName'
          rules={[{ required: true, message: '' }]}
          label={t('addresses.company')}
        >
          <Input type='text' />
        </Form.Item>
        <Form.Item name='strFirstName' label={t('addresses.firstName')}>
          <Input type='text' />
        </Form.Item>
        <Form.Item
          name='strAddress1'
          rules={[{ required: true, message: '' }]}
          label={t('addresses.address')}
        >
          <Input type='text' />
        </Form.Item>
        <Form.Item name='strAddress2' label={t('addresses.address2')}>
          <Input type='text' />
        </Form.Item>
        <Form.Item name='strAddress3' label={t('addresses.address3')}>
          <Input type='text' />
        </Form.Item>
        <Form.Item name='strBuildungCode' label={t('addresses.buildingCode')}>
          <Input type='text' />
        </Form.Item>
        <Form.Item
          name='strZIP'
          rules={[{ required: true, message: '' }]}
          label={t('addresses.zip')}
        >
          <Input type='text' />
        </Form.Item>
        <Form.Item
          name='strCity'
          rules={[{ required: true, message: '' }]}
          label={t('addresses.city')}
        >
          <Input type='text' />
        </Form.Item>
        <Form.Item name='strCountry' label={t('addresses.country')}>
          <Input type='text' />
        </Form.Item>
        <Form.Item name='strPhone' label={t('addresses.phone')}>
          <PhoneInput />
        </Form.Item>
        <Form.Item name='strMobile' label={t('addresses.mobile')}>
          <PhoneInput />
        </Form.Item>
        <Form.Item name='strEmail' label={t('addresses.email')} rules={[{ type: 'email', message: t('users.notValidEmail') }]}>
          <Input type='text' />
        </Form.Item>
        <Form.Item name='strWeb' label={t('addresses.web')}>
          <Input type='text' />
        </Form.Item>
        {
          !selectorMode &&
          <Row justify="center">
            <Col span={24}>
              <Row justify="end">
                <Form.Item>
                  <Button htmlType="submit" disabled={updatingAddress}>
                    {editMode ? t('addresses.confirmChanges') : t('addresses.addAddress')}
                  </Button>
                </Form.Item>
              </Row>
            </Col>
          </Row>
        }
      </Form >
    </FormWrapper >
  )
}

const FormWrapper = styled.div`
  .ant-modal-body {
    height: fit-content;
    @media ${device.tablet} {
      height: initial;
    }
  }
`

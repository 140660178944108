import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { MainLayout } from '../../layouts/MainLayout'
import { Button, Space, Table, Form, Input } from 'antd'
import { useTranslation } from 'react-i18next'
import { device } from '../../style/breakpoints/device'
import { getFromAPI } from '../../context/API'
import { EditOutlined } from '@ant-design/icons'
import { UserModal } from '../../elements/Modal/UserModal'
import { useAuth } from '../../context/AuthProvider'
import { hasAccessToRoute } from '../../utils/RoleUtils'

export const Users = () => {
  const { t } = useTranslation()
  const auth = useAuth()
  const [users, setUsers] = useState([])
  const [editUser, setEditUser] = useState()
  const [emailTimer, setEmailTimer] = useState(null)
  const [searchEmail, setSearchEmail] = useState()
  const [tableLoading, setTableLoading] = useState(true)
  const [fetchController, setFetchController] = useState()


  const handleNameChange = change => {
    if (!searchEmail && change.length < 3) {
      return
    }
    setTableLoading(true)
    if (emailTimer) {
      clearTimeout(emailTimer)
      setEmailTimer(null)
    }
    setEmailTimer(
      setTimeout(() => {
        setSearchEmail(change.length >= 3 ? change : '')
      }, 750)
    )
  }

  useEffect(() => {
    updateUsers(searchEmail)
  }, [searchEmail])

  const updateUsers = (searchEmail) => {
    let url = searchEmail ? 'auth/getUser?' : 'auth/getUserList'
    if (searchEmail) {
      url += 'email=' + encodeURIComponent(searchEmail)
    }
    if (tableLoading && fetchController) {
      fetchController.abort()
      setFetchController()
    }
    const controller = new AbortController();
    // signal to pass to fetch
    const signal = controller.signal;
    setFetchController(controller)
    getFromAPI(url, {}, auth, signal)
      .then(res => res.json())
      .then(data => {
        if (data.response) {
          setUsers(
            data.response.map((item, index) => {
              item.key = index
              return item
            })
          )
          setTableLoading(false)
        } else {
          setUsers([])
          setTableLoading(false)
        }
      })
      .catch((err) => {
        if (err.name !== 'AbortError') {
          setTableLoading(false)
          setUsers([])
        }
      })
  }

  useEffect(() => {
    updateUsers()
  }, [])

  const columns = [
    {
      title: t('users.email'),
      dataIndex: 'Email',
      defaultSortOrder: 'descend',
      sorter: (a, b) => a.email && a.email.localeCompare(b.email)
    },
    {
      title: t('users.firstName'),
      dataIndex: 'strFirstName',
      defaultSortOrder: 'descend',
      sorter: (a, b) => a.strFirstName && a.strFirstName.localeCompare(b.strFirstName)
    },
    {
      title: t('users.surname'),
      dataIndex: 'strName',
      defaultSortOrder: 'descend',
      sorter: (a, b) => a.strName && a.strName.localeCompare(b.strName),
      responsive: ["md"]
    },
    {
      title: t('users.city'),
      dataIndex: 'strCity',
      defaultSortOrder: 'descend',
      sorter: (a, b) => a.strCity && a.strCity.localeCompare(b.strCity),
      responsive: ["md"]
    },
    ...hasAccessToRoute('/updateUser', auth) ? [{
      title: t('users.actions'),
      key: 'action',
      render: (_, record) => (
        <Space size='small'>
          <EditOutlined onClick={() => setEditUser(record)} />
        </Space>
      )
    }] : [],
  ]

  return (
    <MainLayout>
      <div>
        <SearchBar layout='inline'>
          <Form.Item name='name' label={t('users.email')}>
            <Input
              onChange={e => handleNameChange(e.target.value)}
              type='text'
            />
          </Form.Item>
        </SearchBar>
        <Table
          columns={columns}
          dataSource={users}
          showSorterTooltip={false}
          loading={tableLoading}
          scroll={{ x: "scroll"}}
        />
        {hasAccessToRoute('/addUser', auth) &&
          <StyledSpace emptyTable={users.length === 0}>
            <Button
              onClick={() => {
                setEditUser(true)
              }}
            >
              {t('users.addUser')}
            </Button>
          </StyledSpace>}
        <UserModal
          visible={editUser}
          afterClose={() => setEditUser()}
          onSubmit={() => {
            setEditUser()
            updateUsers()
          }}
          values={editUser}
          editMode={editUser !== true}
          title={
            editUser !== true ? t('users.editUser') : t('users.addUser')
          }
        />
      </div>
    </MainLayout>
  )
}

const SearchBar = styled(Form)`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  @media ${device.tablet} {
    flex-direction: row;
  }
  width: 100%;
  margin-bottom: 10px;
`
const StyledSpace = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  margin-top: ${props => (props.emptyTable ? '10px' : '-50px')};
`
